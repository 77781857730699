import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
//import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import Reveal from 'react-reveal/Reveal'
import SEO from '../components/SEO'
import DesktopHeader from '../components/desktopHeader'
import styles from '../styles/pages/contact-styles.module.scss'
import EmailCollect from '../components/emailCollect'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import '../styles/animate.css'

// import emergeImage from '../images/emerge.png'
// import officeImage from '../images/office.png'

const ContactPage = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isMobile: null }
  }
  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true })
    }
    window.onresize = () => {
      this.setState(prevState => ({
        isMobile: window.innerWidth <= 768,
      }))
    }
  }

  render() {
    const sources = [
      {
        ...this.props.data.headshotsMobile.childImageSharp.fluid,
        media: `(max-width: 475px)`,
      },
      {
        ...this.props.data.headshotsDesktop.childImageSharp.fluid,
        media: `(min-width: 476px)`,
      },
    ]
    return (
      <Layout>
        <SEO customTitle="Contact" pagePath="contact" />
        <div className={styles.heroContainer}>
          <div className={styles.backgroundBox} />

          <div className={styles.textGroup}>
            <Reveal effect="fadeInUp">
              <div className={styles.pageTitle}>Contact</div>
            </Reveal>
            <Reveal effect="fadeInUp" duration={1500}>
              <div className={styles.tagline}>
                Completely remote for the moment,{' '}
                <span className={styles.highlightedText}>
                  but give us a shout
                </span>
              </div>
            </Reveal>
            <Reveal effect="fadeInUp" duration={2000}>
              <div className={styles.subheader}>
                Shoot us an email at studio@15finches.com
              </div>
            </Reveal>
          </div>
        </div>
        <div className={styles.headshotsContainer}>
          <Img fluid={sources} style={{ height: '100%', width: '100%' }} />
        </div>
        {/* Old Images of Office*/}
        {/* <ParallaxProvider>
          <div className={styles.imageGroup}>
            <div className={styles.imageContainer}>
              <Parallax
                offsetYMax={'95px'}
                offsetYMin={'-75px'}
                styleInner={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }}
                styleOuter={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 10,
                }}
              >
                <img className={styles.emergeImage} src={emergeImage} alt="" />
              </Parallax>
            </div>
            <div className={styles.imageContainer}>
              <img className={styles.officeImage} src={officeImage} alt="" />
            </div>
          </div>
        </ParallaxProvider> */}
        {/* <div className={styles.contactInfo}>
          <Reveal effect="fadeInUp">
            <div className={styles.title}>Address</div>
            <div className={styles.address}>
              1185 Avenue of the Americas, Floor 3 <br />
              New York, NY 10036
            </div>
          </Reveal>
          <Reveal effect="fadeInUp">
            <div className={styles.title}>Contact</div>
            <div className={styles.email}>studio@15finches.com</div>
          </Reveal>
        </div> */}
        <ContactForm />
        <EmailCollect />
        <Footer isFullHeight={false} />
      </Layout>
    )
  }
}

export default ContactPage

export const contactPageQuery = graphql`
  query {
    headshotsDesktop: file(relativePath: { eq: "headshots-desktop.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headshotsMobile: file(relativePath: { eq: "headshots-mobile.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 475) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
